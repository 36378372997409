.form-input{
  grid-template-columns: 1fr;
}
.nav{
  display: none;
}
.form-input__title{
  display: flex;
  flex-direction: column;
  place-items: center;
}
.background-main svg:nth-child(4){
  display:flex;
}
.background-main svg:nth-child(5){
  display:flex;
}
.background-main svg:nth-child(6){
  display:flex;
}
.login-logo::after{
  content:none;
}
